<template>
  <!-- 页面name：查看 -->
  <div
    class="contain"
    :class="{ long: comStatus == '1' && deptType == 'class' }"
  >
    <div class="form-header-btn">
      <el-page-header @back="goBack" :content="'详情查看'">
        <template slot="title">
          <i class="el-icon-arrow-left"></i>返回
        </template>
      </el-page-header>
      <div v-if="comStatus == '1' && deptType == 'class'">
        <el-button type="primary" class="sheet-btn" @click="handleAddFill">
          新增患病
        </el-button>
      </div>
    </div>
    <div class="con-header-little">
      <i class="el-icon-caret-right" style="margin-right: 0.52083vw"></i>
      <!-- <img class="logo-img" src="../assets/img/jyjy-logo.png" alt="" /> -->
      <span>基本信息</span>
    </div>
    <div class="form">
      <el-row>
        <el-col :span="3">
          <div class="left-label">所属分公司:</div>
        </el-col>
        <el-col :span="21">
          <span class="con-fonts">
            {{ ruleForm.companyName }}
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="left-label">患病园校:</div>
        </el-col>
        <el-col :span="21">
          <span class="con-fonts">
            {{ ruleForm.campusName }}
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="left-label">患病班级:</div>
        </el-col>
        <el-col :span="21">
          <span class="con-fonts">
            {{ ruleForm.classLabel }}
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="left-label">上报时间:</div>
        </el-col>
        <el-col :span="21">
          <span class="con-fonts">
            {{ ruleForm.reportTime }}
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="left-label">传染病名称:</div>
        </el-col>
        <el-col :span="21">
          <span class="con-fonts" v-if="ruleForm.contagionName">
            {{ ruleForm.contagionName.label }}
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="left-label">患病人数:</div>
        </el-col>
        <el-col :span="21">
          <span class="con-fonts">
            {{ ruleForm.sickNum }}
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="left-label">严重程度:</div>
        </el-col>
        <el-col :span="21">
          <span class="con-fonts" v-if="ruleForm.level">
            {{ ruleForm.level.label }}
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="left-label">上报人:</div>
        </el-col>
        <el-col :span="21">
          <span class="con-fonts">
            {{ ruleForm.reportName }}
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="left-label">原因:</div>
        </el-col>
        <el-col :span="21">
          <span class="con-fonts">
            {{ ruleForm.reason }}
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="left-label">采取措施:</div>
        </el-col>
        <el-col :span="21">
          <div class="measure-contain measure-mar">
            <div class="mea-con-tablehead tablehead-backg">
              <div class="tablehead-times tablehead-times-head">时间</div>
              <div class="tablehead-idea tablehead-times-head">采取措施</div>
            </div>
            <div>
              <div
                v-if="ruleForm.historyList && ruleForm.historyList.length > 0"
              >
                <div
                  class="mea-con-tablehead middle-content"
                  v-for="(item, index) in ruleForm.historyList"
                  :key="index"
                >
                  <div class="tablehead-times con-fonts">
                    {{ item.measureTime }}
                  </div>
                  <div class="tablehead-idea con-fonts">{{ item.measure }}</div>
                </div>
              </div>
              <div v-else>
                <div class="mea-con-tablehead middle-content">
                  <div class="tablehead-times con-fonts">
                    {{ ruleForm.measureTime }}
                  </div>
                  <div class="tablehead-idea con-fonts">
                    {{ ruleForm.measure }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row v-if="ruleForm.fileList && ruleForm.fileList.length > 0">
        <el-col :span="3">
          <div class="left-label">附件:</div>
        </el-col>
        <el-col :span="21">
          <div
            class="files-look"
            v-for="(item, index) in ruleForm.fileList"
            :key="index"
          >
            <img
              class="file-smallimg"
              src="../../../assets/img/attach.png"
              alt=""
            />
            <span class="con-fonts">
              {{ item.name }}
            </span>
            <span class="files-button">
              <el-button type="text" @click="showFile(item)">查看</el-button>
              <el-button type="text" @click="downLoadFile(item.id)"
                >下载</el-button
              >
            </span>
          </div>

          <!-- <span class="con-fonts">
            {{ ruleForm.reason }}
          </span> -->
        </el-col>
      </el-row>
    </div>

    <!-- 意见反馈 -->
    <div v-if="ruleForm.historyList && ruleForm.historyList.length > 0">
      <div class="con-header-little">
        <!-- <img class="logo-img" src="../assets/img/jyjy-logo.png" alt="" /> -->
        <i class="el-icon-caret-bottom" style="margin-right: 0.52083vw"></i>
        <span>意见反馈</span>
      </div>
      <div class="form">
        <el-row>
          <el-col :span="3">
            <div class="left-label">处理意见:</div>
          </el-col>
          <el-col :span="21">
            <div class="measure-contain">
              <div class="mea-con-tablehead tablehead-backg">
                <div class="tablehead-times tablehead-times-head">时间</div>
                <div class="tablehead-idea tablehead-times-head">处理意见</div>
              </div>
              <div
                class="mea-con-tablehead middle-content"
                v-for="(item, index) in ruleForm.historyList"
                :key="index"
              >
                <div class="tablehead-times con-fonts">
                  {{ item.commentTime }}
                </div>
                <div class="tablehead-idea con-fonts">{{ item.comment }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <onlinePreview
      :onlinePreviewVisible="onlinePreviewVisible"
      :type="fileAttachment.previewType"
      @closeOnlinePreviewDialog="closeOnlinePreviewDialog"
      :url="fileAttachment.url"
      :title="
        (fileAttachment.attachment_name || '') +
        '.' +
        (fileAttachment.attachment_type || '')
      "
    />
  </div>
</template>

<script>
import downloadFile from "@/utils/downloadFile";
import onlinePreview from "@/components/onlinePreview/index";
export default {
  data() {
    return {
      lookId: "",
      ruleForm: {},
      fileAttachment: {},
      onlinePreviewVisible: false,
    };
  },

  components: {
    onlinePreview,
  },

  mounted() {
    // console.log(this.$route.query.lookId, "分公司执行情况传递过来的参数");
    this.lookId = this.$route.query.lookId;
    this.tableLoading = true;
    this.init();
  },
  methods: {
    /** 初始化 */
    init() {
      let params = {
        id: this.lookId,
      };
      this.$api.getDiseaselookover(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.ruleForm = res.data.data;
          console.log(this.ruleForm.fileList, "长度");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查看文件
    showFile(item) {
      console.log(item, "查看文件查看文件查看文件");
      if (item.pdfPath) {
        let url = "/contagion/attachment/downloadFile";
        let data = {
          id: item.id,
          type: 2,
          flag: 1,
        };
        let type = "get";
        this.onlinePreviewVisible = true;
        this.fileAttachment = {
          previewType: "pdf",
          url: item.pdfPath,
          attachment_name: item.attachment_name,
          attachment_type: item.attachment_type,
        };
      } else {
        this.$message.error("暂不支持在线预览，请下载后查看");
        return;
      }
    },

    //下载文件
    downLoadFile(id) {
      //下载文件
      let url = "/contagion/attachment/downloadFile";
      let data = {
        id: id,
        type: 1,
        flag: 0,
      };
      let type = "get";
      this.tableLoading = true;
      downloadFile({ url, data, type }).then(() => {
        this.tableLoading = false;
      });
    },

    closeOnlinePreviewDialog() {
      this.fileAttachment = {};
      this.onlinePreviewVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";

/deep/.container .layout-main-wrap .layout-main {
  background: #ffffff;
  height: 100%;
  box-sizing: border-box;
  overflow-x: scroll !important;
  overflow-y: scroll;
}
.contain {
  padding: 34px 30px 30px 30px;
  &.long {
    padding: 26px 30px 30px 30px;
  }

  .con-header {
    font-size: 22px;
    font-weight: bold;
    line-height: 27px;
    color: #3d3e4f;
  }

  .con-header-little {
    margin: 40px 0 30px 0;
  }

  .con-header-little span {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #3d3e4f;
  }

  /deep/.el-form-item__label {
    line-height: 16px;
  }

  /deep/.el-form-item__content {
    display: flex;
    align-items: center;
    line-height: 16px;
  }

  /deep/ .el-form-item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .left-label {
    font-size: 16px;
    font-weight: 400;
    color: #606266;
    margin-bottom: 30px;
  }

  .con-fonts {
    font-size: 14px;
    font-weight: 400;
    color: #010334;
  }

  .file-smallimg {
    width: 14px;
    height: 16px;
    margin-right: 8px;
  }

  .measure-mar {
    margin-bottom: 30px;
  }

  .measure-contain {
    width: 1200px;
    min-height: 104px;

    .middle-content {
      border-bottom: 2px solid #e6ebf3;
    }

    .mea-con-tablehead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      font-size: 14px;

      .tablehead-times {
        width: 200px;
        display: flex;
        justify-content: center;
      }
      .tablehead-idea {
        flex: 1;
        display: flex;
      }
    }

    .tablehead-backg {
      height: 48px;
      background: #f1f4f9;
      border-radius: 12px 12px 0px 0px;
      padding: 0;
    }

    .tablehead-times-head {
      font-weight: 400;
      color: #5e6d9b;
    }
  }
  .files-look {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .files-button {
      margin-left: 40px;
    }
  }

  /deep/.el-button--text {
    padding: 0;
  }
}
</style>
